import { CompanyFeaturePermission } from '@ifhms/admin/web/domain/permissions';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';

export const getCompanyNavigationMenuItems = (
  companySlug: string
): SersiNavListItem[] => [
  {
    key: 'operations',
    icon: 'foundation',
    active: false,
    routerLink: [`/company/${companySlug}/operations`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: [`/company/${companySlug}/user-management`],
    requiredPermission: CompanyFeaturePermission.UserManagement,
    subItems: [
      {
        key: 'users',
        routerLink: [`/company/${companySlug}/user-management/users`],
        permissionList: [CompanyFeaturePermission.UserManagement]
      },
      {
        key: 'roles',
        routerLink: [
          `/company/${companySlug}/user-management/roles`
        ],
        permissionList: [CompanyFeaturePermission.UserManagement]
      }
    ]
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    routerLink: [`/company/${companySlug}/reports/animal-health`],
    requiredPermission: CompanyFeaturePermission.IsAdmin,
    subItems: [
      {
        key: 'animal-health',
        icon: 'health_and_safety',
        routerLink: [`/company/${companySlug}/reports/animal-health`],
        subItems: [
          {
            key: 'treatment-history',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/animal-treatment-history`
            ]
          },
          {
            key: 'daily-treatment-history',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/daily-tx-history`
            ]
          },
          {
            key: 'lot-other-detail',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/lot-other-diseases-detail`
            ]
          },
          {
            key: 'lot-uf-detail',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/lot-uf-detail-report`
            ]
          },
          {
            key: 'other-disease-mgmt',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/other-diseases-management`
            ]
          },
          {
            key: 'pm-detail',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/post-mortem-detail`
            ]
          },
          {
            key: 'pm-detail-date',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/post-mortem-detail-by-date`
            ]
          },
          {
            key: 'rail-detail-date',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/rail-detail-by-date`
            ]
          },
          {
            key: 'uf-mgmt',
            routerLink: [
              `/company/${companySlug}/reports/animal-health/uf-management`
            ]
          }
        ]
      },
      {
        key: 'operational',
        icon: 'work_outline',
        routerLink: [`/company/${companySlug}/reports/operational`],
        subItems: [
          {
            key: 'cattle-mvmt',
            routerLink: [
              `/company/${companySlug}/reports/operational/cattle-movement`
            ]
          },
          {
            key: 'event-details',
            routerLink: [
              `/company/${companySlug}/reports/operational/event-details`
            ]
          },
          {
            key: 'hospital-occupancy',
            routerLink: [
              `/company/${companySlug}/reports/operational/hospital-occupancy`
            ]
          },
          {
            key: 'lot-expense-summary',
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-expense-summary`
            ]
          },
          {
            key: 'lot-health-summary',
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-health-summary`
            ]
          },
          {
            key: 'lot-inventory',
            routerLink: [
              `/company/${companySlug}/reports/operational/lot-inventory`
            ]
          },
          {
            key: 'pen-inventory',
            routerLink: [
              `/company/${companySlug}/reports/operational/pen-inventory`
            ]
          },
          {
            key: 'tag-availability',
            routerLink: [
              `/company/${companySlug}/reports/operational/tag-availability`
            ]
          },
          {
            key: 'withdrawal-summary',
            routerLink: [
              `/company/${companySlug}/reports/operational/withdrawal-summary-report`
            ]
          }
        ]
      },
      {
        key: 'product-inventory',
        icon: 'production_quantity_limits',
        routerLink: [`/company/${companySlug}/reports/product-inventory`],
        subItems: [
          {
            key: 'current-pricing',
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/current-product-pricing`
            ]
          },
          {
            key: 'product-on-hand',
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/product-on-hand`
            ]
          },
          {
            key: 'product-usage',
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/product-usage`
            ]
          },
          {
            key: 'reconciled-inventory',
            routerLink: [
              `/company/${companySlug}/reports/product-inventory/reconciled-physical-inventory-value`
            ]
          }
        ]
      },
      {
        key: 'wo-events',
        icon: 'event_note',
        routerLink: [`/company/${companySlug}/reports/work-orders-events`],
        subItems: [
          {
            key: 'arrival-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/arrival-work-order`
            ]
          },
          {
            key: 'lot-transfer-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/lot-transfer-work-order`
            ]
          },
          {
            key: 'movement-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/movement-work-order`
            ]
          },
          {
            key: 'rail-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/rail-work-order`
            ]
          },
          {
            key: 'rehandling-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/rehandling-work-order`
            ]
          },
          {
            key: 'shipment-wo',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/shipment-work-order`
            ]
          },
          {
            key: 'treatment-event',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/treatment-event`
            ]
          },
          {
            key: 'pm-event',
            routerLink: [
              `/company/${companySlug}/reports/work-orders-events/post-mortem-event`
            ]
          }
        ]
      },
      {
        key: 'exports',
        icon: 'upgrade',
        routerLink: [`/company/${companySlug}/reports/exports`],
        subItems: [
          {
            key: 'export-weight-by-wo',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-weight-by-work-order`
            ]
          },
          {
            key: 'export-weight-by-tag',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-weight-by-tag`
            ]
          },
          {
            key: 'export-birthday-by-tag',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-birthday-by-tag`
            ]
          },
          {
            key: 'export-ccia-data',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-ccia-data`
            ]
          },
          {
            key: 'export-custom-fields-by-tag',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-custom-fields-by-tag`
            ]
          },
          {
            key: 'export-lot-billing',
            routerLink: [
              `/company/${companySlug}/reports/exports/export-lot-billing`
            ]
          }
        ]
      }
    ]
  },
  {
    key: 'product-management',
    icon: 'inventory_2',
    active: false,
    routerLink: [`/company/${companySlug}/product-management`],
    requiredPermission: CompanyFeaturePermission.Products
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [`/company/${companySlug}/reference-data`],
    requiredPermission: CompanyFeaturePermission.ReferenceData
  }
];
