import {
  FrmFeaturePermission,
  OperationalFeaturePermission,
  OperationalPermissionsModuleAccess
} from '@ifhms/admin/web/domain/permissions';
import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export const getOperationNavigationMenuItems = (
  companySlug: string,
  feedlotSlug: string
): NavListBaseItem[] => [
  {
    key: 'overview',
    icon: 'dashboard',
    active: false,
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/overview`]
  },
  {
    key: 'operation-list',
    icon: 'foundation',
    active: false,
    routerLink: [`/company/${companySlug}/operations`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/user-management`
    ],
    permissionList: [
      OperationalFeaturePermission.UsersView,
      OperationalFeaturePermission.RolesView
    ],
    subItems: [
      {
        key: 'users',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/user-management/users`
        ],
        permissionList: [OperationalFeaturePermission.UsersView]
      },
      {
        key: 'roles',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/user-management/roles`
        ],
        permissionList: [OperationalFeaturePermission.RolesView]
      }
    ]
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/reference-data`
    ],
    permissionList: [OperationalFeaturePermission.ReferenceDataView]
  },
  {
    key: 'offline-access',
    icon: 'cloud_download',
    active: false,
    isVisible: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/offline-access/data-migration`
    ]
  },
  // {
  //   key: 'enterprise-settings',
  //   icon: 'settings'
  // },
  {
    key: 'divider'
  },
  // {
  //   key: 'enterprise-settings',
  //   icon: 'settings'
  // },
  {
    key: 'operation-settings',
    icon: 'settings',
    active: false,
    routerLink: [
      `/operation/${companySlug}/detail/${feedlotSlug}/operation-settings`
    ],
    subItems: [
      {
        key: 'feedlot-info',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/operation-settings/feedlot-info`
        ],
        permissionList: [OperationalFeaturePermission.SettingsGeneralView]
      }
    ]
  },
  {
    key: 'divider'
  },
  {
    key: 'feedlot',
    icon: 'vaccines',
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/frm`],
    subItems: [
      {
        key: 'lot-billing',
        active: false,
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/lot-billing`
        ],
        permissionList: [FrmFeaturePermission.LotBillingView]
      },
      {
        key: 'tag-mgmt',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/tag-mgmt/tag-management`
        ]
        // TODO THERE IS A ROUTING MODULE CREATED FOR TAG MGMT, IF MORE THAN ONE ITEM IN THIS SECTION USE TAG MGMT MODULE FOR ROUTING
        // AND ABOVE LINK SHOULD BE FRM/TAG-MGMT
        // subItems: [
        //   {
        //     key: 'tag-settings',
        //     routerLink: [
        //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/tag-mgmt/tag-management`
        //     ],
        //     permissionList: [
        //       OperationFeaturePermission.SettingsCustomFieldsManagementRead
        //     ]
        //   }
        // ]
      },
      {
        key: 'reports',
        icon: 'insert_chart',
        active: false,
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health`
        ],
        permissionList: [
          FrmFeaturePermission.ReportsAnimalTreatmentHistory,
          FrmFeaturePermission.ReportsDailyTreatmentHistoryByFacility,
          FrmFeaturePermission.ReportsLotUFDetail,
          FrmFeaturePermission.ReportsOtherDiseasesManagement,
          FrmFeaturePermission.ReportsPostMortemDetail,
          FrmFeaturePermission.ReportsPostMortemDetailByDate,
          FrmFeaturePermission.ReportsUFManagement,
          FrmFeaturePermission.ReportsCattleMovement,
          FrmFeaturePermission.ReportsEventDetails,
          FrmFeaturePermission.ReportsHospitalOccupancy,
          FrmFeaturePermission.ReportsLotExpensesSummary,
          FrmFeaturePermission.ReportsLotHealthSummary,
          FrmFeaturePermission.ReportsLotOtherDiseasesDetail,
          FrmFeaturePermission.ReportsLotInventoryReport,
          FrmFeaturePermission.ReportsPenInventory,
          FrmFeaturePermission.ReportsTagAvailability,
          FrmFeaturePermission.ReportsWithdrawal,
          FrmFeaturePermission.ReportsCurrentProductPricing,
          FrmFeaturePermission.ReportsProductOnHand,
          FrmFeaturePermission.ReportsProductUsage,
          FrmFeaturePermission.ReportsRailDetail,
          FrmFeaturePermission.ReportsReconciledPhysicalInventoryValue,
          FrmFeaturePermission.ReportsArrivalWorkOrder,
          FrmFeaturePermission.ReportsLotTransferWorkOrder,
          FrmFeaturePermission.ReportsMovementWorkOrder,
          FrmFeaturePermission.ReportsRailWorkOrder,
          FrmFeaturePermission.ReportsReHandlingWorkOrder,
          FrmFeaturePermission.ReportsShipmentWorkOrder
        ],
        subItems: [
          {
            key: 'animal-health',
            icon: 'health_and_safety',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health`
            ],
            subItems: [
              {
                key: 'treatment-history',
                label: 'Treatment History',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/animal-treatment-history`
                ]
              },
              {
                key: 'daily-treatment-history',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/daily-tx-history`
                ]
              },
              {
                key: 'lot-other-detail',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/lot-other-diseases-detail`
                ]
              },
              {
                key: 'lot-uf-detail',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/lot-uf-detail-report`
                ]
              },
              {
                key: 'other-disease-mgmt',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/other-diseases-management`
                ]
              },
              {
                key: 'pm-detail',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/post-mortem-detail`
                ]
              },
              {
                key: 'pm-detail-date',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/post-mortem-detail-by-date`
                ]
              },
              {
                key: 'rail-detail-date',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/rail-detail-by-date`
                ]
              },
              {
                key: 'uf-mgmt',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/animal-health/uf-management`
                ]
              }
            ]
          },
          {
            key: 'operational',
            icon: 'work_outline',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational`
            ],
            subItems: [
              {
                key: 'cattle-mvmt',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/cattle-movement`
                ]
              },
              {
                key: 'event-details',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/event-details`
                ]
              },
              {
                key: 'hospital-occupancy',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/hospital-occupancy`
                ]
              },
              {
                key: 'lot-expense-summary',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-expense-summary`
                ]
              },
              {
                key: 'lot-health-summary',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-health-summary`
                ]
              },
              {
                key: 'lot-inventory',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/lot-inventory`
                ]
              },
              {
                key: 'pen-inventory',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/pen-inventory`
                ]
              },
              {
                key: 'tag-availability',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/tag-availability`
                ]
              },
              {
                key: 'withdrawal-summary',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/operational/withdrawal-summary-report`
                ]
              }
            ]
          },
          {
            key: 'product-inventory',
            icon: 'production_quantity_limits',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory`
            ],
            subItems: [
              {
                key: 'current-pricing',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/current-product-pricing`
                ]
              },
              {
                key: 'product-on-hand',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/product-on-hand`
                ]
              },
              {
                key: 'product-usage',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/product-usage`
                ]
              },
              {
                key: 'reconciled-inventory',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/product-inventory/reconciled-physical-inventory-value`
                ]
              }
            ]
          },
          {
            key: 'wo-events',
            icon: 'event_note',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events`
            ],
            subItems: [
              {
                key: 'arrival-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/arrival-work-order`
                ]
              },
              {
                key: 'lot-transfer-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/lot-transfer-work-order`
                ]
              },
              {
                key: 'movement-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/movement-work-order`
                ]
              },
              {
                key: 'rail-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/rail-work-order`
                ]
              },
              {
                key: 'rehandling-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/rehandling-work-order`
                ]
              },
              {
                key: 'shipment-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/shipment-work-order`
                ]
              },
              {
                key: 'treatment-event',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/treatment-event`
                ]
              },
              {
                key: 'pm-event',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/work-orders-events/post-mortem-event`
                ]
              }
            ]
          },
          {
            key: 'exports',
            icon: 'upgrade',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports`
            ],
            subItems: [
              {
                key: 'export-weight-by-wo',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-weight-by-work-order`
                ]
              },
              {
                key: 'export-weight-by-tag',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-weight-by-tag`
                ]
              },
              {
                key: 'export-birthday-by-tag',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-birthday-by-tag`
                ]
              },
              {
                key: 'export-ccia-data',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-ccia-data`
                ]
              },
              {
                key: 'export-custom-fields-by-tag',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-custom-fields-by-tag`
                ]
              },
              {
                key: 'export-lot-billing',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/reports/exports/export-lot-billing`
                ]
              }
            ]
          }
        ]
      },
      {
        key: 'product-management',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management`
        ],
        active: false,
        permissionList: [
          FrmFeaturePermission.ProductDefinitionView,
          FrmFeaturePermission.ProductMappingView,
          FrmFeaturePermission.ProductPricingView,
          FrmFeaturePermission.ProductReceiptView,
          FrmFeaturePermission.ProductReconciliationView,
          FrmFeaturePermission.ProductLotNoExpiryView,
          FrmFeaturePermission.ProductTransactionView
        ],
        subItems: [
          {
            key: 'products',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/products`
            ],
            permissionList: [FrmFeaturePermission.ProductDefinitionView]
          },
          {
            key: 'receipts',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/receipts/overview`
            ],
            permissionList: [FrmFeaturePermission.ProductReceiptView]
          },
          {
            key: 'reconciliation',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/product-management/reconciliation/overview`
            ],
            permissionList: [FrmFeaturePermission.ProductReconciliationView]
          }
        ]
      },
      {
        key: 'protocols',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/protocols/treatment-protocols`
        ]
        // TODO THERE IS A ROUTING MODULE CREATED FOR PROTOCOLS, IF MORE THAN ONE ITEM IN THIS SECTION USE PROTOCOLS MODULE FOR ROUTING
        // AND ABOVE LINK SHOULD BE FRM/PROTOCOLS
        // subItems: [
        //   {
        //     key: 'treatment-protocols',
        //     active: false,
        //     routerLink: [
        //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/protocols/treatment-protocols`
        //     ],
        //     permissionList: [OperationFeaturePermission.SettingsPostMortemRead]
        //   }
        // ]
      },
      {
        key: 'charges',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges`
        ],
        subItems: [
          {
            key: 'labour-charges',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/labour-charges`
            ],
            permissionList: [FrmFeaturePermission.SettingsLabourChargesView]
          },
          {
            key: 'procedure-charges',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/procedure-charges`
            ],
            permissionList: [FrmFeaturePermission.SettingsProcedureChargesView]
          },
          {
            key: 'other-fees',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/charges/other-fees`
            ],
            permissionList: [FrmFeaturePermission.SettingsOtherFeesView]
          }
        ]
      },
      {
        key: 'data-imports',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/data-imports/import-animals`
        ]
        // TODO THERE IS A ROUTING MODULE CREATED FOR DATA-IMPORTS, IF MORE THAN ONE ITEM IN THIS SECTION USE DATA IMPORTS MODULE FOR ROUTING
        // AND ABOVE LINK SHOULD BE FRM/DATA-IMPORTS
        // subItems: [
        //   {
        //     key: 'animal-import',
        //     routerLink: [
        //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/data-imports/import-animals`
        //     ],
        //     permissionList: [OperationFeaturePermission.SettingsGeneralRead]
        //   }
        // ]
      },
      {
        key: 'integrations',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations`
        ],
        subItems: [
          {
            key: 'ccia',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/ccia-integration`
            ],
            permissionList: [FrmFeaturePermission.SettingsCCIAView]
          },
          // {
          //   key: 'post-mortem',
          //   routerLink: [
          //     `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/integrations/post-mortem-integration`
          //   ],
          //   permissionList: [OperationFeaturePermission.SettingsCCIARead]
          // },
          {
            key: 'turnkey',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/turnkey-integration`
            ]
          },
          {
            key: 'pro-feeders',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/integrations/pro-feeder-integration`
            ]
          }
        ]
      },
      {
        key: 'settings',
        icon: 'settings',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings`
        ],
        permissionList: [OperationalFeaturePermission.SettingsGeneralView],
        subItems: [
          {
            key: 'global-settings',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/global-settings`
            ],
            subItems: [
              {
                key: 'pricing',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/global-settings/pricing`
                ],
                permissionList: [
                  FrmFeaturePermission.SettingsPricingSettingsView
                ]
              },
              {
                key: 'global-default',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/global-settings/default-values`
                ],
                permissionList: [FrmFeaturePermission.SettingsDefaultValuesView]
              },
              {
                key: 'global-target',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/global-settings/target-weights/overview`
                ],
                permissionList: [FrmFeaturePermission.SettingsDefaultValuesView]
              }
            ]
          },
          {
            key: 'wo-and-events',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events`
            ],
            subItems: [
              {
                key: 'arrival',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events/arrival`
                ],
                permissionList: [FrmFeaturePermission.SettingsArrivalView]
              },
              {
                key: 'rehandling',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events/rehandling`
                ],
                permissionList: [FrmFeaturePermission.SettingsReHandlingView]
              },
              {
                key: 'adg-settings',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events/adg-settings`
                ],
                permissionList: [FrmFeaturePermission.SettingsPostMortemView]
              },
              {
                key: 'zero-scale-reminder',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events/zero-scale-reminder`
                ],
                permissionList: [FrmFeaturePermission.SettingsPostMortemView]
              },
              {
                key: 'treatment',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/wo-events/treatment-protocol`
                ],
                permissionList: [
                  FrmFeaturePermission.TreatmentProtocolSettingsView,
                  FrmFeaturePermission.TreatmentProtocolSettingsEdit
                ]
              }
            ]
          },
          {
            key: 'custom-fields',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/custom-fields`
            ],
            permissionList: [
              FrmFeaturePermission.SettingsCustomFieldsManagementView
            ]
            // TODO THERE IS A ROUTING MODULE CREATED FOR CUSTOM FIELDS, IF MORE THAN ONE ITEM IN THIS SECTION USE CUSTOM FIELDS MODULE FOR ROUTING
            // subItems: [
            //   {
            //     key: 'custom-fields-mgmt',
            //     routerLink: [
            //       `/operation/${companySlug}/feedlot/${feedlotSlug}/frm/settings/custom-fields`
            //     ],
            //     permissionList: [
            //       OperationFeaturePermission.SettingsCustomFieldsManagementRead
            //     ]
            //   }
            // ]
          },
          {
            key: 'sort-group',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/sort-group`
            ],
            subItems: [
              {
                key: 'sort-group-settings',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/sort-group/sort-group-settings`
                ],
                permissionList: [FrmFeaturePermission.SettingsSortGroupView]
              },
              {
                key: 'sort-group-templates',
                routerLink: [
                  `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/sort-group/sort-group-templates`
                ],
                permissionList: [
                  FrmFeaturePermission.SettingsSortGroupTemplatesView
                ]
              }
            ]
          },
          {
            key: 'post-mortem',
            routerLink: [
              `/operation/${companySlug}/detail/${feedlotSlug}/frm/settings/post-mortem`
            ],
            permissionList: [FrmFeaturePermission.SettingsPostMortemView]
          }
        ]
      }
    ]
  },
  {
    key: 'procurement',
    icon: 'content_paste_search',
    routerLink: [`/operation/${companySlug}/detail/${feedlotSlug}/procurement`],
    permissionList: [OperationalPermissionsModuleAccess.Procurement],
    subItems: [
      {
        key: 'procurement-settings',
        routerLink: [
          `/operation/${companySlug}/detail/${feedlotSlug}/procurement`
        ],
        permissionList: [OperationalFeaturePermission.SettingsGeneralView]
      }
    ]
  }
  // {
  //   key: 'close-out',
  //   icon: 'point_of_sale',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     }
  //   ]
  // },
  // {
  //   key: 'psmt',
  //   icon: 'price_check',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     },
  //     {
  //       key: 'cattle-type'
  //     }
  //   ]
  // },
  // {
  //   key: 'post-mortem',
  //   icon: 'medical_services',
  //   subItems: [
  //     {
  //       key: 'settings'
  //     }
  //   ]
  // },
];
